import { UtilService } from "modules/booking/shared/util-service";
import "quill-paste-smart";
import React, { ReactFragment, Ref } from "react";
import { useController } from "react-hook-form";
import ReactQuill from "react-quill";
import { ReactQuillProps } from "react-quill/lib";

import {
  FieldItemError,
  Heading,
  IStackStyles,
  IStyle,
  mergeStyles,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { FormFieldProps } from "./types";

type FormRichTextEditorProps = FormFieldProps &
  Omit<ReactQuillProps, "style"> & {
    containerStyles?: IStackStyles;
    label?: string;
    disabled?: boolean;
    withMaxCharactersToShow?: number;
    bottomMessage?: ReactFragment;
  };

export const FormRichTextEditor = React.forwardRef(
  (props: FormRichTextEditorProps, ref: Ref<ReactQuill>) => {
    const theme = useTheme();
    const {
      name,
      rules,
      containerStyles,
      label,
      disabled,
      bottomMessage,
      withMaxCharactersToShow,
      ...quillProps
    } = props;

    const {
      field: { value, onChange },
      fieldState: { error }
    } = useController({ name, rules });

    const strippedPrevValue = UtilService.getTextFromHtml(value ?? "").trim();

    const count = strippedPrevValue.length;

    const handleChange = (content: string) => {
      const strippedValue = UtilService.getTextFromHtml(content ?? "");

      onChange(strippedValue.length ? content : null);
    };

    const getErrorStyles = (): IStyle | undefined =>
      error?.message
        ? {
            ".ql-toolbar.ql-snow": {
              borderColor: theme.semanticColors.errorText
            },
            ".ql-container.ql-snow": {
              borderColor: theme.semanticColors.errorText
            }
          }
        : undefined;

    const className = mergeStyles(quillProps.className, getErrorStyles(), {
      ".ql-toolbar,.ql-container": {
        backgroundColor: disabled
          ? theme.semanticColors.disabledBackground
          : "none"
      },
      ".ql-editor": { wordBreak: "break-word" }
    });

    return (
      <Stack className="QuillBounds" styles={containerStyles}>
        {label && (
          <Heading
            labelPaddings
            styles={{
              root: {
                "&::after": rules?.required && {
                  content: "'*'",
                  color: theme.palette.redDark,
                  paddingLeft: 4
                }
              }
            }}
          >
            {label}
          </Heading>
        )}

        <ReactQuill
          ref={ref}
          bounds=".QuillBounds"
          {...quillProps}
          className={className}
          value={value}
          readOnly={disabled}
          onChange={handleChange}
        />
        <Stack
          horizontal
          styles={{ root: { justifyContent: "space-between" } }}
        >
          {error?.message && (
            <FieldItemError name={name} errorMessage={error?.message} />
          )}
          <Stack
            styles={(_prop, theme) => ({
              root: {
                color: theme.semanticColors.disabledText,
                marginLeft: "auto",
                paddingTop: 8
              }
            })}
            horizontal
            tokens={{ childrenGap: 16 }}
          >
            {bottomMessage}
            {withMaxCharactersToShow && (
              <Text>
                {count}/{withMaxCharactersToShow}
              </Text>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }
);
