import { WarningFontIcon } from "modules/booking/components/WarningFontIcon";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { Link, MessageBar, MessageBarType, Text } from "@bps/fluent-ui";

interface MultiApptWarningMessageBarProps {}
export const MultiApptWarningMessageBar: FunctionComponent<
  MultiApptWarningMessageBarProps
> = () => {
  const navigate = useNavigate();

  const appointmentTypesLink = (
    <Link
      onClick={() => {
        navigate("/booking/appointment-types", { replace: true });
      }}
    >
      Appointment Types
    </Link>
  );

  const providersLink = (
    <Link
      onClick={() => {
        navigate("/booking/providers", { replace: true });
      }}
    >
      Providers
    </Link>
  );

  return (
    <MessageBar
      messageBarType={MessageBarType.severeWarning}
      styles={{
        iconContainer: { display: "none" },
        innerText: { whiteSpace: "none" },
        root: { minHeight: "auto" }
      }}
    >
      <Text variant="small">
        Your appointment type has been set up to allow multiple bookings per
        appointment slot in Bp Premier. Please review and update impacted
        'custom' durations in {appointmentTypesLink} and {providersLink}.
        Records requiring action are denoted with the following icon
      </Text>
      <WarningFontIcon
        iconStyles={{ root: { marginLeft: 4, paddingBottom: 2 } }}
      />
    </MessageBar>
  );
};
