import timezones from "compact-timezone-list";
import _ from "lodash";
import { IEnum } from "modules/booking/shared/models/common.model";

import { IDropdownOption, SelectableOptionMenuItemType } from "@bps/fluent-ui";

export const NO_TAGS_REGEX = /(<([^>]+)>)/gi;

export class UtilService {
  public static getEnumDescription(type: IEnum, key: any): string {
    if (!type) return "";
    return type[key];
  }

  public static getTimeZones(): IDropdownOption[] {
    const timezoneOptions: IDropdownOption[] = [];
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const allTimeZones = timezones.map(z => {
      return z.tzCode as string;
    }) as string[];

    if (browserTimeZone != null) {
      timezoneOptions.push(
        ...[
          {
            key: "Header1",
            text: "Browser Time Zone",
            itemType: SelectableOptionMenuItemType.Header
          },
          { key: browserTimeZone, text: this.cleanTzName(browserTimeZone) },
          {
            key: "divider_1",
            text: "-",
            itemType: SelectableOptionMenuItemType.Divider
          }
        ]
      );
      _.remove(allTimeZones, t => t === browserTimeZone);

      const country = browserTimeZone.split("/")[0];
      timezoneOptions.push(
        {
          key: "Header2",
          text: "Region Time Zones",
          itemType: SelectableOptionMenuItemType.Header
        },
        ..._.filter(allTimeZones, t => t.startsWith(country)).map(t => {
          return { key: t, text: this.cleanTzName(t) };
        }),
        {
          key: "divider_2",
          text: "-",
          itemType: SelectableOptionMenuItemType.Divider
        }
      );
      _.remove(allTimeZones, t => t.startsWith(country));

      timezoneOptions.push(
        {
          key: "Header3",
          text: "World Time Zones",
          itemType: SelectableOptionMenuItemType.Header
        },
        ..._.map(allTimeZones, t => {
          return { key: t, text: this.cleanTzName(t) };
        }),
        {
          key: "divider_3",
          text: "-",
          itemType: SelectableOptionMenuItemType.Divider
        }
      );
    }

    return timezoneOptions;
  }

  public static cleanTzName(name: string) {
    return name.replace(/_/g, " ");
  }

  public static getTextFromHtml(value: string | undefined) {
    return (value ?? "").replace(NO_TAGS_REGEX, "").trim();
  }
}
