import { PropsWithChildren, ReactNode } from "react";
import { Mode } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import {
  DefaultValues,
  UseFormProps,
  UseFormReturn
} from "react-hook-form/dist/types/form";

import { IStyle, IStyleFunctionOrObject, ITheme } from "@bps/fluent-ui";

import { FormSubmitCancelButtonsProps } from "./FormSubmitCancelButtons";

export interface FormProps<FormValues extends FieldValues = FieldValues>
  extends PropsWithChildren<{
    /**
     * Optional prop to pass external form api in a case you need to have the api access outside the Form component
     */
    form?: UseFormReturn<FormValues>;
    defaultValues?: DefaultValues<FormValues>;
    validationMode?: Mode;
    reValidateMode?: UseFormProps["reValidateMode"];
    onSubmit: (
      values: FormValues,
      form: UseFormReturn<FormValues>
    ) => Promise<void>;
    styles?: IStyleFunctionOrObject<FormStylesProps, FormStyles>;
    id?: string;
    onRenderSubmitCancelButtons?: (
      form: UseFormReturn<FormValues>
    ) => ReactNode;
    submitCancelButtonsProps?: FormSubmitCancelButtonsProps<FormValues>;
    /**
     * Activates form auto-saving.
     */
    autoSave?: boolean;
    /**
     * Optional prop what sets auto-save debouncing delay. Default is 500ms.
     */
    autoSaveDelay?: number;
  }> {}

export interface FormStyles {
  root: IStyle;
  fields: IStyle;
  buttons: IStyle;
}

export interface FormStylesProps {
  theme: ITheme;
}

export const defaultConfirmationDialogButtonsText = {
  discard: "Discard",
  reset: "Reset",
  cancel: "Keep editing"
};

export const FORM_PROMPT_DISCARD_MESSAGE = "Discard your changes?";
export const FORM_PROMPT_RESET_MESSAGE = "Reset your changes?";
